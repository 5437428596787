import playstore1 from "../../assets/Footer2/app-store 1.png";
import playstore2 from "../../assets/Footer2/google-play 1.png";
import man from "../../assets/Footer2/man.png";
import { footer1, sportsOptions, footer3 } from "./helper";
import "./styles.scss";
import axios from "axios";
import Footerbottom from "components/footer/Footerbottom";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function Footer() {
  const [isChatbotFixed, setIsChatbotFixed] = useState(false);
  const [isActiveFooter, setisActiveFooter] = useState({});
  const [visitors, setVisitors] = useState([]);
  const history = useHistory();

  function openCloseAccordian(name) {
    setisActiveFooter({
      ...isActiveFooter,
      [name]: !Boolean(isActiveFooter[name]),
    });
  }

  useEffect(() => {
    // Define the async function for fetching visitors
    const fetchVisitors = async () => {
      try {
        const response = await axios.get(
          "https://gms.38nguk.in/api/get-visitors"
        );
        setVisitors(response.data); // Assuming response.data contains the visitors data
      } catch (err) {
        console.error("Error fetching visitors:", err);
      }
    };

    fetchVisitors();
  }, []);

  useEffect(() => {
    const trackIpAddress = async () => {
      try {
        // Step 1: Get the visitor's IP address
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;
        console.log("Visitor's IP Address:", ipAddress);

        // Step 2: Post the IP address to the API
        const postResponse = await axios.post(
          "https://gms.38nguk.in/api/save-ip",
          {
            ip_address: ipAddress, // Payload structure (match API requirements)
          }
        );

        console.log("IP saved successfully:", postResponse.data);
      } catch (error) {
        console.error("Error tracking or saving IP address:", error);
      }
    };

    trackIpAddress();
  }, []);

  // const formatVisitorCount = (count) => {
  //   if (!count) return 0; // Default to 0 if count is null or undefined
  //   if (count >= 1000) {
  //     return Math.floor(count / 1000) + "k"; // Convert to 'k' format
  //   }
  //   return count; // Return the original count if less than 1000
  // };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Function to update the state with the new window dimensions
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const documentHeight = document.body.scrollHeight;
    const isBottom = windowHeight + scrollY + 100 >= documentHeight;
    // console.log(` {
    //   windowHeight:${windowHeight},
    //   scrollY:${scrollY},
    //   documentHeight:${documentHeight},
    //   isBottom:${isBottom},

    // }`);
    setIsChatbotFixed(!isBottom);
  };

  window.addEventListener("scroll", handleScroll);

  const socialMediaIcons = [
    {
      id: 1,
      name: "Instagram",
      component: <FaInstagram />,
      link: "https://www.instagram.com/uksportsdept/"
    },
    {
      id: 2,
      name: "Facebook",
      component: <FaFacebook />,
      link: "https://www.facebook.com/@UttarakhandSportsDepartment"
    },
    {
      id: 3,
      name: "Twitter",
      component: <BsTwitterX />,
      link: "https://x.com/uksportsdept?s=09&mx=2"
    },
    {
      id: 4,
      name: "YouTube",
      component: <FaYoutube />,
      link: "https://www.youtube.com/@uksportsdept"
    },
    {
      id: 5,
      name: "LinkedIn",
      component: <FaLinkedin />,
      link: "https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEf33cY1RlQdwAAAZSIpH2ILrPvo15F4qAvwD-8udVuoD82RS8Jff9fQFYMWeuiPCBO7jAK3t0dyfJ7kb0jCrPrZBE4SyuvJM5HS9UWlleROh7qHKNV42LxdIHEw8NCSLNHyHo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fuksportsdept%2Fabout%2F%3FviewAsMember%3Dtrue"
    }
  ];



  return (
    <div>
      <div className="uttrakhand_footer_page4">
        <div className="f-1_page4" >
          {/* <p className="footer_heading">38TH NATIONAL GAMES 2025 </p> */}
          {/* <div
            style={{ marginBottom: "10px" }}
            className="fontstyle footer_open_close "
          >
            <span name="ng24" onClick={() => openCloseAccordian("ng24")}>
              {isActiveFooter?.ng24 ? "-" : "+"}
            </span>
          </div> */}
          <p className="footer_heading"> Games </p>
          {
            footer1.map((option, index) => (
              <div key={index} className="footer-fields_page4">
                <a
                  href={option?.path || "/"}
                  style={{
                    textDecoration: "none",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  {option.name}
                </a>
              </div>
            ))}
        </div>
        {/* ---------------- */}
        <div className="f-2_page4" >
          <p className="footer_heading"> ABOUT </p>
          {/* <div className="fontstyle footer_open_close">
            <span onClick={() => openCloseAccordian("sport")}>
              {isActiveFooter?.sport ? "-" : "+"}
            </span>
          </div> */}
          {
            sportsOptions.map((option, index) => (
              <div key={index} className="footer-fields_page4">
                <a
                  href={option?.path || "/"}
                  style={{
                    textDecoration: "none",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  {option.name}
                </a>
              </div>
            ))}

        </div>
        {/* {(windowSize?.width > 1100 || isActiveFooter?.sport) && ( */}
        <>
          <div className="">
            <p className="footer_heading"> NEWS & MEDIA </p>
            {footer3.map((option, index) => (
              <div key={index} className="footer-fields_page4">
                <a
                  href={option?.path || "/"}
                  style={{
                    textDecoration: "none",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  {option.name}
                </a>
              </div>
            ))}
          </div>
          {/* <div className="f-4_page4 ">
              {footer4.map((option, index) => (
                <div key={index} className="footer-fields_page4">
                  <a
                    href={option?.link || "/"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {option.name}
                  </a>
                </div>
              ))}
            </div> */}
        </>
        {/* )} */}

        {/* ---------------- */}

        <div className="f-5_page4">
          <p className="footer_heading">UKNGOC Info</p>
          {/* <div
            style={{ marginBottom: "-4px" }}
            className="fontstyle footer_open_close"
          >
            <span onClick={() => openCloseAccordian("ukgoc")}>
              {isActiveFooter?.ukgoc ? "-" : "+"}
            </span>
          </div> */}
          {/* {(windowSize?.width > 1100 || isActiveFooter?.ukgoc) && ( */}
          <>
            {/* <div className="footer-fields_page4">Director Sports,</div> */}
            {/* <div
              className="footer-fields_page4"
              style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: "15px" }}
            >
              Director Sports, <br />
            </div> */}
            <div
              style={{ color: "rgba(255, 255, 255, 0.5)", fontSize: "15px" }}
            >
              <p className="ukgoc-detail">
                Sports Directorate, Uttarakhand <br />
              </p>
              <p className="ukgoc-detail">
                {" "}
                Maharana Pratap Sports College
                <br /> Campus, Raipur, <br />
              </p>
              {/* <p className="ukgoc-detail">
              {/* <p className="ukgoc-detail">
                {" "}
                Sports College Campus, Thano Road, Raipur <br />
              </p> */}
              {/* </p> */} 
              <p className="ukgoc-detail">
                {" "}
                Dehradun-248008 <br />
              </p>
              {/* <p className="ukgoc-detail">
                {" "}
                Ph- 0135-2781414
                <br />
              </p> */}
            </div>

            {/* <div className="footer-fields_page4">
                Maharana Pratap Sports College
                <br /> Campus,Raipur, Dehradun
              </div> */}
            {/* <div className="footer-fields_page4">Pin- 248008</div> */}
            {/* <div className="footer-fields_page4">Ph- 0135-2781414</div> */}
            <div
              className="footer-fields_page4"
              style={{
                color: "rgba(255, 255, 255, 0.5)",
                fontSize: "15px",
                margin: "0px",
              }}
            >
              Email Id : ngsuttarakhand@ gmail.com
            </div>
            <div
              className=""
              style={{
                fontSize: "15px",
                margin: "0px",
              }}
            >
              {/* Helpline Number: 8035237901 */}
              24x7 Helpline & grievance <br />
              
            </div>
            <div
              className=""
              style={{
                color: "rgba(255, 255, 255, 0.5)",
                fontSize: "15px",
                margin: "0px",
              }}
            >
            <p className="ukgoc-detail">
                {" "}
                Phone Number: 8035237901 <br />
              </p>
              </div>
            <div
              
            >
              <a href="https://grievances.ileads.co.in/" target="_blank" rel="noopener noreferrer" style={{
                fontSize: "15px",
                margin: "0px",
              }} className="grivence">Grievance Portal</a>
            </div>
          </>
          {/* )} */}
        </div>

        <div className="f-6-appstore">
          <div className="footer_heading">DOWNLOAD APP</div>
          <div>
            <a
              href="https://apps.apple.com/in/app/nguk38/id6740802757"
              target="_blank" // Opens the link in a new tab
              rel="noopener noreferrer" // Improves security by preventing the new page from accessing the `window.opener` object
            >
              <img src={playstore1} alt="play-store" className="appstore_logo" />
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.khelotech.a38thngappuk"
              target="_blank" // Opens the link in a new tab
              rel="noopener noreferrer" // Improves security by preventing the new page from accessing the `window.opener` object
            >
              <img src={playstore2} alt="apple-store" className="googlestore_logo" />
            </a>
          </div>
          <div>
            <ul className="social_icons_wrapper">
              {socialMediaIcons.map((icondata) => (
                <li key={icondata.id} className="social_icons mx-1 mt-2">
                  <a href={icondata.link} target="_blank" >
                    {icondata.component}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>


      </div>

      <div
        className={`chatbot-section  ${isChatbotFixed ? "chatbot-fixed " : "chatbot-above-footer"
          }`}
      >
        <div>
          <img width="35" src={man} alt="" />

          {/* <button className="helpline-text visitor-count">
            Visitor Count: {formatVisitorCount(visitors.visitorCount) ?? 0}
            
          </button> */}
        </div>
        {/* <div>
          <img width="60" src={chatbot} alt="" />
        </div> */}
        <div className="footer-bottom ">
          <Footerbottom />
        </div>
      </div>

      <div className="footer-lower_page4">
        <div>Copyright © Uttarakhand National Games organizing committee</div>
        <div className="footer-left">Terms and Condition</div>
        {/* <div className="footer-right"> */}
        <div>Visitors : {visitors?.visitorCount}</div>

        <div
          className="cursor-pointer"
          onClick={() => history.push("/privacy-policy")}
        >
          Privacy Policy
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Footer;
